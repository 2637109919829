<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>

        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs mt-2"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="checkoutData"
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <refund-request-items
              :items="requestItems"
              class="mb-5"
            />

            <order-overview
              v-for="(order, index) in checkoutData.orders"
              :key="order.id"
              :order="order"
              :show-checkout="index === 0"
              :receipt-email="checkoutData.receipts[0].email"
              :receipt="checkoutData.receipts[0]"
              @updated="reloadCheckout"
            />
          </v-tab-item>
          <v-tab-item>
            <order-items-list
              v-for="order in checkoutData.orders"
              :key="order.id"
              :order="order"
              @updated="reloadCheckout"
              @openShipmentDialog="openShipmentDialog"
            />
          </v-tab-item>
          <v-tab-item>
            <order-nature-details
              v-for="order in checkoutData.orders"
              :key="order.id"
              :order="order"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="showShipmentFormDialog"
        persistent
        max-width="800px"
      >
        <shipment-form
          ref="shipmentForm"
          :order="orderToShip"
          @updated="reloadCheckout"
          @close="showShipmentFormDialog = false"
        ></shipment-form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { ShipmentForm } from '@/components'
import router from '@/router'
import { mdiAlphaOBox, mdiInboxMultiple, mdiMore } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import OrderItemsList from '../order-view/order-overview/OrderItemsList.vue'
import OrderNatureDetails from '../order-view/order-overview/OrderNatureDetails.vue'
import OrderOverview from '../order-view/order-overview/OrderOverview.vue'
import RefundRequestItems from '../refund-request-resource/RefundRequestItems.vue'
import useCheckoutView from './useCheckoutView'

export default {
  components: {
    OrderOverview,
    OrderItemsList,
    OrderNatureDetails,
    ShipmentForm,
    RefundRequestItems,
  },
  setup() {
    const { loadCheckout, checkoutData } = useCheckoutView()

    const reloadCheckout = () => {
      checkoutData.value = null

      return loadCheckout(router.currentRoute.params.id)
    }

    // UnRegister on leave

    const tabs = computed(() => [
      { icon: mdiAlphaOBox, title: 'OVERVIEW' },
      { icon: mdiInboxMultiple, title: 'ORDERS' },
      { icon: mdiMore, title: 'EXTRAS' },
    ])
    const currentTab = ref(0)

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    onMounted(() => reloadCheckout().then(switchToDefaultTab))

    // Shipment Formn Dialog
    const showShipmentFormDialog = ref(false)
    const orderToShip = ref(null)

    const openShipmentDialog = data => {
      orderToShip.value = checkoutData.value.orders.find(order => order.id === data.orderId)
      showShipmentFormDialog.value = true
    }

    const requestItems = computed(() => {
      if (!checkoutData.value) {
        return []
      }
      let items = []
      checkoutData.value.payments.forEach(payment => {
        items = items.concat(payment.refund_requests)
      })

      return items
    })

    return {
      tabs,
      checkoutData,
      reloadCheckout,
      requestItems,

      currentTab,

      // Shipment dialog
      showShipmentFormDialog,
      orderToShip,
      openShipmentDialog,
    }
  },
}
</script>
